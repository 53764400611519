.imgButton {
  border: none;
  cursor: pointer;
  appearance: none;
  background-color: inherit;
}

.rTable {
  max-width: 100%;
  overflow-x: auto;
  scrollbar-width: thin !important;
  scrollbar-color: #afafaf transparent !important;
}

.rTable::-webkit-scrollbar {
  width: 8px !important;
}

.rTable::-webkit-scrollbar-track {
  background-color: #f1f1f1 !important;
}

.rTable::-webkit-scrollbar-thumb {
  background: #888 !important;
  background-color: #888 !important;
  border-radius: 6px !important;
}

::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

::-webkit-scrollbar-thumb {
  background: transparent;
}

::-webkit-scrollbar-track {
  background: transparent;
}

/* .shadowClass {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
} */
.shadowClass {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2), 0 5px 19px 0 rgba(0, 0, 0, 0.19) !important;
}

.loadingModal {
  background-color: transparent !important;
  border-width: 0px !important;
}

.ModalOpen {
  animation: openModal 0.3s ease-out forwards;
}
.ModalClosed {
  animation: closeModal 0.3s ease-out forwards;
}
.BDModalOpen {
  animation: BDopenModal 0.3s ease-out forwards;
}
.BDModalClosed {
  animation: BDcloseModal 0.3s ease-out forwards;
}

@keyframes openModal {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes closeModal {
  0% { opacity: 1; }
  100% { opacity: 0; }
}
@keyframes BDopenModal {
  0% { opacity: 0; }
  100% { opacity: 0.6; }
}

@keyframes BDcloseModal {
  0% { opacity: 0.6; }
  100% { opacity: 0; }
}

Button {
  font-size: 10px !important; 
}

hr {
  opacity: 10%;
}

textarea {
  /* Hide default scrollbar */
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
}

textarea::-webkit-scrollbar {
  width: 8px;
}

textarea::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

textarea::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 6px;
}

.crossOutOnHover {
  text-decoration: none; /* Initial style without decoration */
  transition: text-decoration 0.3s ease; /* Smooth transition effect */

  /* Hover style with text-decoration: line-through; */
  &:hover {
    text-decoration: line-through;
  }
}

.ordered .MuiDataGrid-cell {
  background-color: #429647;
  color: white;
}

.approved .MuiDataGrid-cell {
  background-color: #b3b003;
  /* background-color: #c7b302; */
  color: white;
}

.not-approved .MuiDataGrid-cell {
  background-color: #E84848;
  color: white;
}

.awaiting-approval .MuiDataGrid-cell {
  color: black;
}

.datagrid-table-custom-headers {
  background-color: #e9f0ee;
  color: #26695c;
}

.scrollbar-data-grid {
  /* Hide default scrollbar */
  scrollbar-width: thin;
  scrollbar-color: #e6e5e5 transparent;
}

.scrollbar-data-grid::-webkit-scrollbar {
  width: 8px;
}

.scrollbar-data-grid::-webkit-scrollbar-track {
  background-color: #e6e5e5;
}

.scrollbar-data-grid::-webkit-scrollbar-thumb {
  background-color: #cfcfcf;
}

/* Hover effect on the scrollbar track */
.scrollbar-data-grid:hover {
  scrollbar-color: #cfcfcf transparent;
}

.scrollbar-data-grid::-webkit-scrollbar-thumb:hover {
  background: #cfcfcf; 
}

.long-text-data-grid {
  overflow: hidden;
  text-overflow: ellipsis;
}

.textButtonContainer {
  display: flex;      /* This turns flexbox on for the container */
  align-items: center; /* This vertically centers the items in the container */
}

.entrata .MuiDataGrid-cell {
  background-color: #b7ffcf;
}

.trasferimento .MuiDataGrid-cell {
  background-color: #bedeff;
}

.uscita .MuiDataGrid-cell {
  background-color: #ffbdbd;
}

.todayShift {
  background-color: #befad2 !important;
}

.otherDaysShifts {
  background-color: #fdcdcd !important;
}

.textCenter {
  text-align: center;
}

.form-required-symbol {
  color: red;
}

.custom-editor-container {
  border-radius: 8px; 
}
.custom-editor-container .ql-toolbar {
  border-color: #e6edef !important; /* Toolbar border color */
  border-top-left-radius: 8px; /* Top-left radius */
  border-top-right-radius: 8px; /* Top-right radius */
}
.custom-editor-container .ql-container {
  border-color: #e6edef !important; /* Editor border color */
  border-bottom-left-radius: 8px; /* Bottom-left radius */
  border-bottom-right-radius: 8px; /* Bottom-right radius */
}

.kiosk-scale-smaller {
  height: 570px;
  transform: scale(0.80);
  transform-origin: top center;
  margin: 0;
  padding: 0;
  overflow: 'hidden';
  display: 'inline-block';
}